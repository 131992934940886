import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
@Component({
    selector: 'app-dishes',
    templateUrl: './dishes.component.html',
    styleUrls: ['./dishes.component.css']
})
export class DishesComponent implements OnInit {
    dishName: any;
    dishAcPrice: any;
    dishVipPrice: any;
    dishDeluxePrice: any;
    dishParcelPrice: any;
    allDishList: any = 0;
    modalBoxName: any = 'Create Dish';
    editDishId: any;
    editDishName: any;
    editDishAcPrice: any;
    editDishVipPrice: any;
    editDishDeluxePrice: any;
    editDishParcelPrice: any;
    editIngredient = [];
    viewDishName: any;
    viewDishAcPrice: any;
    viewDishVipPrice: any;
    viewDishDeluxePrice: any;
    viewDishParcelPrice: any;
    viewIngredient = [];
    viewDishCategory = [];
    show = false;
    viewBox = false;
    searchField: FormControl = new FormControl();
    listDishes: any = [];
    p = 1;
    stockNameList = [];
    keyword = 'name';
    activeCategoryList;
    stockId;
    dishForm: FormGroup;
    editDishForm: FormGroup;
    viewDishDetail = [];
    viewDishIngredient = [];
    editDishDetail: any = [];
    activeCategoryName = [];
    dropdownSettings = {};
    CategoryNameList = [];
    listHalls: any = [];
    hallNameList: any = [];
    loading = false;
    loadingBtn = false;
    allBranchlist: any = [];
    errorMsg: any = [];
    activeBranchList: any = [];
    editDishPrice: any = [];
    branchClass = -1;
    selectBranchId: any = '';
    selectBranchName: any = '';

    showNotAvailable = false;
    choosed:any = false;
    imageUploadURL:any = '';
    viewImage = false;
    imageView:any = '';
    selectedImage:any = '';
    selectedId:any = '';
    selectedName:any = '';
    imageUrl: any = '';
    @ViewChild('auto', { static: true }) auto;
    @ViewChild('branch', { static: true }) branch;
    roleId: any = `${localStorage.getItem('role_id')}`;
    roleName: any = `${localStorage.getItem('role')}`;
    @ViewChild('dropdownRef', { static: false }) dropdownRef: AngularMultiSelect;
    @ViewChild('dishImage', { static: false }) dishImage: ElementRef;
    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event:
        KeyboardEvent) {
        this.closeViewBox();
        this.popUpClose();
    }
    constructor(private apiService: ApiService, private toastr: ToastrService, private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.searchDish();
        this.listActiveBranches();
        this.imageUrl = this.apiService.getImageUrl();
        this.dishForm = this.formBuilder.group({
            branch_id: new FormControl('', Validators.required),
            branch_name: new FormControl({ value: '', disabled: true }, Validators.required),
            dish_name: new FormControl('', Validators.required),
        });
        this.activeCategoryName = [];
        this.dropdownSettings = {
            singleSelection: false,
            text: 'Select Dish Category',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            classes: 'myclass custom-class'
        };
        this.editDishForm = new FormGroup({
            branch_name: new FormControl({ value: '', disabled: true }, Validators.required),
            branch_id: new FormControl('', [Validators.required]),
            dish_id: new FormControl('', [Validators.required]),
            dish_name: new FormControl('', [Validators.required]),
        });
    }

    editIngredientRow() {
        return this.formBuilder.group({
            ingName: new FormControl('', Validators.required),
            stock_id: new FormControl('', Validators.required),
            stock_volume: new FormControl('', Validators.required),
            stock_quantity: new FormControl('', Validators.required),
        });
    }

    ingredientRow() {
        return this.formBuilder.group({
            ingName: new FormControl('', Validators.required),
            stock_id: new FormControl('', Validators.required),
            stock_volume: new FormControl('', Validators.required),
            stock_quantity: new FormControl('', Validators.required),
        });
    }

    branchNameSelect(id, name, index) {
        this.listDishes = [];
        this.searchField.patchValue('');
        this.p = 1;
        this.hallNameList.forEach((specimen: any) => {
            this.dishForm.removeControl(specimen.hall_price_column_name);
        });
        this.hallNameList.forEach((specimen: any) => {
            this.editDishForm.removeControl(specimen.hall_price_column_name);
        });
        this.selectBranchId = id;
        this.selectBranchName = name;
        this.branchClass = index;
        this.hallNameList = [];
        this.listAllDishes();
        this.listActiveHalls();
    }

    listActiveBranches() {
        this.loading = true;
        this.apiService.getData('listActiveBranches').subscribe(data => {
            this.loading = false;
            if (this.roleName === 'user') {
                this.activeBranchList = data.data;
            } else {
                this.selectBranchId = `${localStorage.getItem('branchId')}`;
                this.selectBranchName = `${localStorage.getItem('branchName')}`;
                this.activeBranchList = [{
                    branch_id: this.selectBranchId, branch_name: this.selectBranchName
                }];
            }
            if (this.activeBranchList.length !== 0) {
                setTimeout(() => {
                    const branchClick = document.querySelector('#branchname') as HTMLElement;
                    branchClick.click();
                });
            }
        }, error => {
            this.loading = false;
        });
    }

    listAllDishes() {
        this.loading = true;
        this.apiService.getData('listAllDishes', this.selectBranchId).subscribe((data) => {
            this.listDishes = data.data;
            if(this.listDishes.length === 0) {
                this.showNotAvailable = true;
            } else {
                this.showNotAvailable = false;
            }
            this.loading = false;
        });
    }

    listActiveHalls() {
        this.hallNameList = [];
        this.apiService.getData('listActiveHalls', this.selectBranchId).subscribe((data) => {
            this.listHalls = data.data;
            const hallList = data.data;
            let i;
            for (i = 0; i < hallList.length; i++) {
                this.hallNameList.push({ hall_price_column_name: hallList[i].hall_price_column_name, hall_name: hallList[i].hall_name });
            }
            this.hallNameList.forEach((specimen: any) => {
                this.dishForm.addControl(specimen.hall_price_column_name, new FormControl('', Validators.required));
            });
            this.hallNameList.forEach((specimen: any) => {
                this.editDishForm.addControl(specimen.hall_price_column_name, new FormControl('', Validators.required));
            });
        });
    }

    openModalBox(id = '') {
        if (id) {
            this.show = true;
            this.editDishForm.reset();
            this.modalBoxName = 'Edit Dish';
            this.apiService.getData('viewDish', `${this.selectBranchId}/${id}`).subscribe((data) => {
                this.editDishDetail = data.data;
                this.editDishPrice = data.data.dishPrice;
                let i;
                this.editDishForm.get('dish_id').patchValue(this.editDishDetail.dish_id);
                this.editDishForm.get('dish_name').patchValue(this.editDishDetail.dish_name);
                this.editDishForm.get('branch_name').patchValue(this.editDishDetail.branch.branch_name);
                this.editDishForm.get('branch_id').patchValue(this.editDishDetail.branch.branch_id);
                this.hallNameList.forEach((specimen: any) => {
                    const element = this.editDishForm.get(specimen.hall_price_column_name);
                    element.patchValue(this.editDishDetail[specimen.hall_price_column_name]);
                });
            });
        } else {
            this.dishForm.reset();
            this.show = true;
            this.modalBoxName = 'Create Dish';
            this.dishForm.reset();
            this.dishForm.get('branch_id').setValue(this.selectBranchId);
            this.dishForm.get('branch_name').setValue(this.selectBranchName);
        }
    }
    popUpClose() {
        this.show = false;
    }

    viewDish(id) {
        this.viewBox = true;
        this.apiService.getData('viewDish', `${this.selectBranchId}/${id}`).subscribe((data) => {
            this.viewDishDetail = data.data;
            this.viewDishName = data.data.dish_name;
        });
    }

    closeViewBox() {
        this.viewBox = false;
    }


    createDish() {
        this.dishForm.markAllAsTouched();
        const value = this.dishForm.value;
        this.loadingBtn = true;
        this.apiService.postData(value, 'createDish').subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.listAllDishes();
                this.popUpClose();
                this.loadingBtn = false;
            } else {
                this.toastr.warning(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
            }
        }, error => {
            this.loadingBtn = false;
        });
    }

    updateDish() {
        const value = this.editDishForm.value;
        if (this.editDishForm.valid) {
            this.loadingBtn = true;
            this.apiService.postData(value, 'updateDish').subscribe(data => {
                if (data.error === false) {
                    this.toastr.success(data.message);
                    this.listAllDishes();
                    this.popUpClose();
                    this.loadingBtn = false;
                } else {
                    this.toastr.warning(data.message);
                    this.loadingBtn = false;
                }
            }, error => {
                this.loadingBtn = false;
            });
        } else {
            this.toastr.error('Please Fill Mandatory Params');
        }

    }

    activateDish(id) {
        this.apiService.getData('activateDish', id).subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
            } else {
                this.toastr.error(data.message);
            }
            this.listAllDishes();
        });
    }

    deActivateDish(id) {
        this.apiService.getData('deActivateDish', id).subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
            } else {
                this.toastr.error(data.message);
            }
            this.listAllDishes();
        });
    }

    deleteDish(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.apiService.getData('deleteDish', id).subscribe(data => {
                    if (data.error === false) {
                        this.toastr.success(data.message);
                    } else {
                        this.toastr.error(data.message);
                    }
                    this.listAllDishes();
                });
                Swal.fire(
                    'Deleted!',
                    'Your imaginary file has been deleted.',
                    'success'
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                );
            }
        });
    }

    searchDish() {
        this.searchField.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged(),
                switchMap((query: string) => this.apiService.searchData('searchDish', query ? `${this.selectBranchId}/${query}` : `${this.selectBranchId}/`)
                ))
            .subscribe((result) => {
                if (this.searchField.value === '') {
                    this.listAllDishes();
                    return false;
                }
                if (result.data.length === 0) {
                    this.listDishes = '';
                } else {
                    this.listDishes = result.data;
                }
            });
    }

    onChangeSearch(val: string) {
        if (val === '') {
            this.auto.close();
            return false;
        }
    }

    onFocused(e) {
        if (this.auto) {
            this.auto.close();
        }
    }

    dropDownOpen(event: any) {
        this.dropdownRef.openDropdown();
    }

    openImagePopUp(id, name, image) {
        this.selectedId = id;
        this.selectedName = name;
        this.imageView = image;
        this.viewImage = true;
    }

    closeImagePopUp() {
        this.viewImage = false;
    }

    updateDishImage() {
        const formData = new FormData();
        formData.append('dish_image', this.selectedImage);
        formData.append('dish_id', this.selectedId);
        this.apiService.postData(formData, 'updateDishImage').subscribe((data) => {
            if(!data.error) {
                this.toastr.success(data.message);
                this.closeImagePopUp();
                this.listAllDishes();
                this.choosed = false;
                this.dishImage.nativeElement.value = "";
            } else {
                this.toastr.warning(data.message);
            }
        })
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.choosed = true;
            this.selectedImage = event.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
              this.imageView = reader.result;
            }
        }
    }


}
