import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-branches',
    templateUrl: './branches.component.html',
    styleUrls: ['./branches.component.css']
})
export class BranchesComponent implements OnInit {
    show = false;
    branchName;
    cityName;
    editBranchId;
    editBranchName;
    editCityName;
    modalBoxName;
    branchList: any = [];
    p = 1;
    branchCount;
    loading = false;
    loadingBtn = false;
    searchField: FormControl = new FormControl();
    constructor(private apiService: ApiService, private toastr: ToastrService) { }

    ngOnInit() {
        this.listAllBranches();
        this.searchBranch();
    }

    createBranch() {
        this.loadingBtn = true;
        const value = { branch_name: this.branchName, city_name: this.cityName }
        this.apiService.postData(value, 'createBranch').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.listAllBranches();
                this.branchName = '';
                this.cityName = '';
                this.popUpClose();
                this.loadingBtn = false;
            } else {
                this.toastr.warning(data.message);
                this.loadingBtn = false;
            }
        }, error => {
            this.loadingBtn = false;
        });
    }

    openModalBox(id = '', name = '', city = '') {
        this.show = true;
        if (name && city) {
            this.modalBoxName = 'Edit Branch';
            this.editBranchId = id;
            this.editBranchName = name;
            this.editCityName = city;
        } else {
            this.modalBoxName = 'Create Branch';
            this.editBranchName = '';
            this.editCityName = '';
            this.branchName = '';
            this.cityName = '';
        }
    }

    listAllBranches() {
        this.loading = true;
        this.apiService.getData('listAllBranches').subscribe((data) => {
            this.branchList = data.data;
            this.branchCount = this.branchList.length;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    updateBranch() {
        if (!this.editBranchName || !this.editCityName) {
            this.toastr.error('Missing the mandatory params to update');
            return false;
        }
        this.loadingBtn = true;
        const value = { branch_id: this.editBranchId, branch_name: this.editBranchName, city_name: this.editCityName };
        this.apiService.postData(value, 'updateBranch').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.popUpClose();
                this.modalBoxName = 'Create Branch Details';
                this.listAllBranches();
                this.loadingBtn = false;
            } else {
                this.toastr.warning(data.message);
                this.loadingBtn = false;
            }
        });
    }

    activateBranch(id) {
        this.apiService.getData('activateBranch', id).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.listAllBranches();
            } else {
                this.toastr.error(data.message);
            }
        });
    }

    deActivateBranch(id) {
        this.apiService.getData('deActivateBranch', id).subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.listAllBranches();
            } else {
                this.toastr.error(data.message);
            }
        });
    }

    searchBranch() {
        this.searchField.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged(), switchMap((query) =>
                this.apiService.searchData('searchBranch', query)
            ))
            .subscribe((result) => {
                if (this.searchField.value === '') {
                    this.listAllBranches();
                    return false;
                }
                if (result.data.length === 0) {
                    this.branchList = '';
                } else {
                    this.branchList = result.data;
                }
            });
    }

    deleteBranch(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Branch Detail',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.apiService.getData('deleteBranch', id).subscribe((data) => {
                    if (data.error === false) {
                        this.toastr.success(data.message);
                        this.listAllBranches();
                    } else {
                        this.toastr.error(data.message);
                    }
                });

                Swal.fire(
                    'Deleted!',
                    'Your Branch detail has been deleted.',
                    'success'
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your Branch detail file is safe :)',
                    'error'
                );
            }
        });
    }

    popUpClose() {
        this.show = false;
    }

}
