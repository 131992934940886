import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-expense',
    templateUrl: './expense.component.html',
    styleUrls: ['./expense.component.css']
})
export class ExpenseComponent implements OnInit {

    expenseName;
    expenseAmount;
    expenseList: any = [];
    p = 1;
    searchField: FormControl = new FormControl();
    loadingBtn = false;
    loading = false;
    activeBranchList = [];
    selectBranchId: any = '';
    selectBranchName: any = '';
    branchClass = -1;
    roleId: any = `${localStorage.getItem('role_id')}`;
    roleName: any = `${localStorage.getItem('role')}`;
    constructor(private apiService: ApiService, private toastr: ToastrService) { }

    ngOnInit() {
        this.searchExpense();
        this.listActiveBranches();
    }

    listActiveBranches() {
        this.loading = true;
        this.apiService.getData('listActiveBranches').subscribe((data) => {
            this.loading = false;
            if (this.roleName === 'user') {
                this.activeBranchList = data.data;
            } else {
                this.selectBranchId = `${localStorage.getItem('branchId')}`;
                this.selectBranchName = `${localStorage.getItem('branchName')}`;
                this.activeBranchList = [{
                    branch_id: this.selectBranchId, branch_name: this.selectBranchName
                }];
            }
            if (this.activeBranchList.length !== 0) {
                setTimeout(() => {
                    const branchClick = document.querySelector('#branchname') as HTMLElement;
                    branchClick.click();
                });
            }
        }, error => {
            this.loading = false;
        });
    }

    branchNameSelect(id, name, index) {
        this.expenseList = [];
        this.searchField.patchValue('');
        this.p = 1;
        this.selectBranchId = id;
        this.selectBranchName = name;
        this.branchClass = index;
        this.listAllExpense();
    }


    createExpense() {
        if (this.expenseName && this.expenseAmount) {
            this.loadingBtn = true;
            const value = { expense_name: this.expenseName, expense_amount: this.expenseAmount, branch_id: this.selectBranchId };
            this.apiService.postData(value, 'createExpense').subscribe((data) => {
                if (data.error === false) {
                    this.toastr.success(data.message);
                    this.listAllExpense();
                    this.expenseName = '';
                    this.expenseAmount = '';
                    this.loadingBtn = false;
                } else {
                    this.toastr.warning(data.message);
                    this.loadingBtn = false;
                }
            }, error => {
                this.loadingBtn = false;
            });
        } else {
            this.toastr.error('Please fill mandatory params to create');
        }
    }

    listAllExpense() {
        this.loading = true;
        this.apiService.getData('listAllExpense', this.selectBranchId).subscribe((data) => {
            this.expenseList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    searchExpense() {
        this.searchField.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged(),
                switchMap((query: string) => this.apiService.searchData('searchExpense', query ? `${this.selectBranchId}/${query}` : `${this.selectBranchId}/`)
                )).subscribe((result) => {
                    if (this.searchField.value === '') {
                        this.listAllExpense();
                        return false;
                    }
                    if (result.data.length === 0) {
                        this.expenseList = '';
                    } else {
                        this.expenseList = result.data;
                    }
                });
    }


}
