import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-cashiers',
    templateUrl: './cashiers.component.html',
    styleUrls: ['./cashiers.component.css']
})
export class CashiersComponent implements OnInit {

    shiftId = `${localStorage.getItem('shift_id')}`;
    cashierCode: any;
    cashierName: any;
    allCashierList: any = [];
    modalBoxName: any = 'Create Cashier';
    editcashierBranch = '';
    editcashierBranchName = '';
    editCashierId: any;
    editCashierName: any;
    editCashierCode: any;
    editcashierPassword: any;
    showpopup = false;
    isActive = false;
    isActiveCon = false;
    searchField: FormControl = new FormControl();
    p = 1;
    cashierUserName;
    cashierPassword;
    cashierConfirmPassword;
    activeBranchList: any = [];
    loading = false;
    errorMsg: any = [];
    loadingBtn = false;
    selectBranchId: any = '';
    selectBranchName: any = '';
    branchClass = -1;
    constructor(private apiService: ApiService, private toastr: ToastrService) { }

    ngOnInit() {
        this.searchCashier();
        this.listActiveBranches();
    }

    passwordVisible() {
        this.isActive = true;
    }

    passwordinVisible() {
        this.isActive = false;
    }

    conPasswordVisible() {
        this.isActiveCon = true;
    }

    conPasswordinVisible() {
        this.isActiveCon = false;
    }

    branchNameSelect(id, name, index) {
        this.allCashierList = [];
        this.searchField.patchValue('');
        this.p = 1;
        this.selectBranchId = id;
        this.selectBranchName = name;
        this.branchClass = index;
        this.listAllCashiers();
    }

    listAllCashiers() {
        this.loading = true;
        this.apiService.getData('listAllCashiers', this.selectBranchId).subscribe(data => {
            this.allCashierList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    openModalBox(branch = '', branchName = '', id = '', name = '', code = '') {
        this.showpopup = true;
        if (id && name) {
            this.modalBoxName = 'Edit Cashier';
            this.editcashierBranch = branch;
            this.editcashierBranchName = branchName;
            this.editCashierId = id;
            this.editCashierName = name;
            this.editCashierCode = code;
            this.editcashierPassword = '';

        } else {
            this.modalBoxName = 'Create Cashier';
            this.cashierName = this.cashierCode = '';
            this.cashierPassword = '';
            this.cashierConfirmPassword = '';
        }
    }

    popUpClose() {
        this.showpopup = false;
    }

    listActiveBranches() {
        this.loading = true;
        this.apiService.getData('listActiveBranches').subscribe(data => {
            this.activeBranchList = data.data;
            this.loading = false;
            if (this.activeBranchList.length !== 0) {
                setTimeout(() => {
                    const branchClick = document.querySelector('#branchname') as HTMLElement;
                    branchClick.click();
                });
            }
        }, error => {
            this.loading = false;
        });
    }

    createCashier() {
        this.loadingBtn = true;
        const params = {
            branch_id: this.selectBranchId,
            cashier_name: this.cashierName,
            cashier_code: this.cashierCode,
            password: this.cashierPassword,
            confirm_password: this.cashierConfirmPassword
        };
        this.apiService.postData(params, 'createCashier').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                const closeModalBox: HTMLElement = document.getElementById('closeModal') as HTMLElement;
                closeModalBox.click();
                this.loadingBtn = false;
            } else {
                this.errorMsg = data.data;
                this.toastr.error(data.message);
                this.loadingBtn = false;
                setTimeout(() => {
                    this.errorMsg = [];
                }, 3000);
            }
            this.listAllCashiers();
        }, error => {
            this.loadingBtn = false;
        });
    }

    updateCashier() {
        this.loadingBtn = true;
        if (!this.editcashierBranch || !this.editCashierName || !this.editCashierCode) {
            this.toastr.error('Missing the mandatory params to update');
            return false;
        }
        const value = {
            branch_id: this.editcashierBranch,
            cashier_id: this.editCashierId,
            cashier_name: this.editCashierName,
            cashier_code: this.editCashierCode,
            password: this.editcashierPassword
        };
        this.apiService.postData(value, 'updateCashier').subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
                const closeModalBox: HTMLElement = document.getElementById('closeModal') as HTMLElement;
                closeModalBox.click();
                this.editCashierName = this.editCashierCode = this.editCashierId = this.editcashierPassword = '';
                this.modalBoxName = 'Create Cashier';
                this.popUpClose();
                this.loadingBtn = false;
            } else {
                this.toastr.error(data.message);
                this.loadingBtn = false;
            }
            this.listAllCashiers();
        }, error => {
            this.loadingBtn = false;
        });
    }

    activateCashier(id) {
        this.apiService.getData('activateCashier', id).subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
            } else {
                this.toastr.error(data.message);
            }
            this.listAllCashiers();
        });
    }

    deActivateCashier(id) {
        this.apiService.getData('deActivateCashier', id).subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
            } else {
                this.toastr.error(data.message);
            }
            this.listAllCashiers();
        });
    }

    deleteCashier(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.apiService.getData('deleteCashier', id).subscribe(data => {
                    if (data.error === false) {
                        this.toastr.success(data.message);
                    } else {
                        this.toastr.error(data.message);
                    }
                    this.listAllCashiers();
                });
                Swal.fire(
                    'Deleted!',
                    'Your imaginary file has been deleted.',
                    'success'
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                );
            }
        });
    }

    searchCashier() {
        this.searchField.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged(),
                switchMap((query: string) => this.apiService.searchData('searchCashier', query ? `${this.selectBranchId}/${query}` : `${this.selectBranchId}/`)
                )).subscribe((result) => {
                    if (this.searchField.value === '') {
                        this.listAllCashiers();
                        return false;
                    }
                    if (result.data.length === 0) {
                        this.allCashierList = '';
                    } else {
                        this.allCashierList = result.data;
                    }
                });
    }

}
