import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-create-bill',
    templateUrl: './create-bill.component.html',
    styleUrls: ['./create-bill.component.css']
})
export class CreateBillComponent implements OnInit {
    @ViewChild('auto', { static: true }) auto;
    @ViewChild('qty', { static: false }) qty: ElementRef;
    @ViewChild('auto', { static: true, read: ElementRef }) dishAuto: ElementRef;
    @ViewChild('autoTable', { static: true, read: ElementRef }) autoTable: ElementRef;
    @ViewChild('hall', { static: true }) hallEl: ElementRef;

    branchId = `${localStorage.getItem('branchId')}`;
    myDate = new Date();
    num: number = 1;
    keyword = 'name';
    data = [];
    tableData = [];
    results: any[] = [];
    queryField: FormControl = new FormControl();
    activeHallList: any;
    activeCashierList: any;
    activeServerList: any;
    activeDishList: any;
    hallId: any = '';
    hallInfo: any = [];
    cashierId: any = `${localStorage.getItem('id')}`;
    serverId: any = '';
    selectedDishDetails: any = '';
    dishQty: any = '';
    currentBill: any = [];
    currentDishes: any = [];
    currentDishId: any = '';
    currentDishQty: any = '';
    hallName: any = '';
    cashierName: any = '';
    serverName: any = '';
    currentBillDetails: any = [];
    currentHall: any = '';
    currentCashier: any = '';
    currentServer: any = '';
    currentDish: any = [];
    currentSubTotal: any = 0;
    currentGrandTotal: any = 0;
    currentTotalItems: any = 0;
    currentBillId: any = 0;
    disableHalls: any = false;
    date = formatDate(new Date(), 'dd-MM-yy', 'en');
    time = formatDate(new Date(), 'h:mm a', 'en');
    currentDate = '';
    currentTime = '';
    dishAutoComplete;
    billNumber = 0;
    showServer = true;
    orderType = '';
    tableName;
    userBranchId;
    cashierBranchId: any = `${localStorage.getItem('branchId')}`;
    currentCashierId = `${localStorage.getItem('id')}`;
    role: any = `${localStorage.getItem('role')}`;
    allDiningTables;
    loading = false;
    loadingBtn = false;
    imageUrl:any = '';
    name:any = '';
    mobile:any = '';
    mode:any = 'cash';
    gst:any = 'yes';
    showGst = true;
    cgst: any = '';
    sgst: any = '';
    parcel: any = '';
    sgstValue = 0;
    cgstValue = 0;
    parcelValue = 0;
    showParcel = false;
    roundOff = '0.00';
    showCustomer = false;
    tempBillId:any = '';
    kotBillDetails:any = [];
    showKot = false;

    constructor(private apiService: ApiService, private toastr: ToastrService) { }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if(event.altKey && event.key === 'p') {
            this.createBill();
        }
    }

    ngOnInit() {
        this.currentBill.branch_id = this.branchId;
        this.currentBill.hall_id = 0;
        this.currentBill.cashier_id = this.currentCashierId;
        this.currentBill.server_id = 0;
        this.currentBill.bill_overall_total = 0;
        this.currentBill.bill_id = Math.random().toString(36).substring(7);
        this.createBillDetails();
        this.getBillNumber();
        this.cancelBill();
        this.getSettings();
        this.imageUrl = this.apiService.getImageUrl();
        this.hallEl.nativeElement.focus();

    }

    getSettings() {
        this.apiService.getData('getSettings').subscribe((data) => {
            const settings = data.data;
            this.cgst = settings.cgst_percentage;
            this.sgst = settings.sgst_percentage;
            this.parcel = settings.parcel_percentage;
        });
    }

    createBillDetails() {
        this.apiService.getData('createBillDetails', this.cashierBranchId).subscribe((data) => {
            this.activeHallList = data.data.hall;
            this.activeCashierList = data.data.cashier;
            this.activeServerList = data.data.server;
            //Construct Dish Autocomplete
            this.activeDishList = data.data.dish;
            let i;
            for (i = 0; i < this.activeDishList.length; i++) {
                this.data.push({ id: this.activeDishList[i].dish_id, name: this.activeDishList[i].dish_name, image: this.imageUrl.concat(this.activeDishList[i].dish_image)});
            }

            //Construct Table Autocomplete
            if (this.role === 'cashier') {
                this.userBranchId = this.cashierBranchId;
            } else {
                this.userBranchId = '0';
            }
        });
    }

    getBillNumber() {
        this.apiService.getData('getBillNumber').subscribe((data) => {
            this.billNumber = +data.data;
        });
    }

    customerChanged() {
        if(this.name || this.mobile) {
            this.showCustomer = true;
        } else {
            this.showCustomer = false;
        }
    }

    hallChanged() {
        this.hallId = this.hallInfo.hall_id;
        if(this.hallId === 2) {
            this.showParcel = true;
        } else {
            this.showParcel = false;
        }
        this.currentBill.hall_id = this.hallId;
        this.disableHalls = true;
        this.liveOrders();
    }

    modeChanged() {
        if(!this.mode) {
            this.toastr.warning('Please Choose Payment Mode');
        }
        this.liveOrders();
    }

    gstChanged() {
        this.showGst = this.gst === 'yes' ? true : false;
        this.liveOrders();
    }

    selectedDish(dish) {
        this.checkHallSelected();
        this.qty.nativeElement.focus();
        this.selectedDishDetails = dish;
    }

    checkHallSelected() {
        if (this.hallId === '') {
            this.toastr.error('Please choose a hall first');
            this.auto.clear();
            return;
        }
    }

    dishQtychange(e) {
        if (e.keyCode === 13 && this.dishQty !== '') {
            this.liveOrdersDishes();
        }
    }

    removeCurrentDish(dishId) {
        this.loading = true;
        const parcel = this.hallId === 2 ? 'yes' : 'no';
        this.apiService.getData('removeCurrentDish', `${dishId}/${this.gst}/${parcel}`).subscribe((data) => {
            this.loading = true;
            this.liveOrderDetails();
        }, error => {
            this.loading = false;
        });
    }

    liveOrders() {
        this.loading = true;
        this.time = formatDate(new Date(), 'h:mm:ss a', 'en');
        const bills = {
            bill_id: this.currentBill.bill_id,
            bill_number: this.billNumber,
            branch_id: this.branchId,
            hall_id: this.currentBill.hall_id,
            cashier_id: this.cashierId === '' ? 0 : this.cashierId,
            order_type: 'cashier',
            customer_name: this.name,
            customer_mobile: this.mobile,
            date: this.date,
            time: this.time,
            gst : this.gst,
            payment_mode: this.mode
        };
        const dish = {
            dish_id: this.currentDishId,
            dish_quantity: this.currentDishQty
        };
        const value = { bills, dish };
        this.apiService.postData(value, 'liveOrders').subscribe((data) => {
            this.liveOrderDetails();
        }, error => {
            this.loading = false;
        });
    }

    liveOrdersDishes() {
        this.currentBillDetails.dish = [];
        if (this.selectedDishDetails && this.dishQty) {
            this.currentDishes = [];
            this.currentDishId = this.currentDishQty = '';
            if (this.currentDishes.includes(this.selectedDishDetails.id) === false) {
                this.currentDishId = this.selectedDishDetails.id;
                this.currentDishQty = this.dishQty;
                this.liveOrders();
                this.auto.clear();
                setTimeout(() => this.dishAuto.nativeElement.firstChild.firstElementChild.firstElementChild.focus());
                this.dishQty = '';
            }
        } else {
            this.toastr.error('Please Fill All the Details to Place Bill');
            return;
        }
    }

    liveOrderDetails() {
        this.loading = true;
        this.apiService.getData('liveOrderDetails').subscribe((data) => {
            this.loading = true;
            this.currentBillDetails = data.data;
            this.currentBillId = data.data.bill_id;
            this.roundOff = this.currentBillDetails.round_off;
            if (this.currentBillDetails.hall.length) {
                this.currentHall = this.currentBillDetails.hall[0].hall_name;
            }
            if (this.currentBillDetails.cashier.length) {
                this.currentCashier = this.currentBillDetails.cashier[0].cashier_name;
            }
            if (this.currentBillDetails.dish.length) {
                this.currentDish = this.currentBillDetails.dish;
                this.currentTotalItems = this.currentBillDetails.dish_count;
                this.cgstValue = this.currentBillDetails.cgst_value;
                this.sgstValue = this.currentBillDetails.sgst_value;
                this.parcelValue = this.currentBillDetails.parcel_value;
                this.currentSubTotal = this.currentBillDetails.bill_sub_total;
                this.currentGrandTotal = this.currentBillDetails.bill_overall_total;
                this.currentDishId = this.currentDishQty = '';
            } else {
                this.currentDishId = this.currentDishQty = '';
                this.currentDish = this.currentBillDetails.dish = [];
                this.currentTotalItems = this.currentSubTotal = this.currentGrandTotal = this.cgstValue = this.sgstValue = this.parcelValue = 0;
            }
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    createBill() {
        if (!(this.hallId && this.cashierId && this.currentDish.length)) {
            this.toastr.error('Please Fill All the Details to Place Bill');
            return;
        }
        this.loadingBtn = true;
        // this.liveOrders();
        const billId = {
            bill_id: this.currentBillId,
            bill_number: this.billNumber
        };
        this.apiService.postData(billId, 'createBill').subscribe((data) => {
            if (data.error === false) {
                this.billNumber = data.data.bill_number;
                this.toastr.success(data.message);
                this.printBill();
                this.kotBill();
                this.createFreshBill();
                this.loadingBtn = false;
            } else {
                this.toastr.error(data.message);
                this.loadingBtn = false;
            }
        });
    }

    kotBill() {
        this.apiService.getData('getKotBill').subscribe((data) => {
            this.kotBillDetails = data.data;
            console.log(this.kotBillDetails.bill_number);
            this.showKot = true;
            setTimeout(() => {
                this.printKotBill();
            });
            setTimeout(() => {
                this.showKot = false;
                this.hallEl.nativeElement.focus();
            });
        })
    }

    cancelBill() {
        this.apiService.getData('cancelBill').subscribe(() => {
            this.createFreshBill();
        });
    }

    printBill() {
        const printContent = document.getElementById('bill_container');
        const WindowPrt = window.open('', '', 'left=0,top=0,width=700,height=700,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write('<style type="text/css"></style>');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.print();
        WindowPrt.document.close();
    }

    printKotBill() {
        const printContent = document.getElementById('kot_container');
        const WindowPrt = window.open('', '', 'left=0,top=0,width=700,height=700,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write('<style type="text/css"></style>');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.print();
        WindowPrt.document.close();
    }

    createFreshBill() {
        this.hallEl.nativeElement.focus();
        this.disableHalls = this.showKot = false;
        this.date = formatDate(new Date(), 'dd-MM-yy', 'en');
        this.time = formatDate(new Date(), 'h:mm a', 'en');
        this.roundOff = '0.00';
        this.cgstValue = this.sgstValue = this.currentSubTotal = this.parcelValue = 0;
        this.serverId = this.currentHall = this.currentServer = this.currentDishId = this.currentDishQty = this.name = this.mobile = '';
        this.currentDishes = [];
        this.currentBillDetails = [];
        this.currentBill = [];
        this.currentBill.bill_id = Math.random().toString(36).substring(7);
        this.currentBill.hall_id = this.hallId;
        this.currentDish = [];
        this.auto.close();
        this.currentTotalItems = this.currentGrandTotal = 0;
        this.auto.clear();
        this.showServer = true;
        this.getBillNumber();
    }

    onChangeSearch(val: string) {
        if (val === '') {
            this.auto.close();
            return false;
        }
    }

    onFocused(e) {
        this.auto.close();
    }

    openPanel(e): void {
        this.auto.open();
    }

    onInputClear(e) {
        this.auto.close();
        this.dishQty = '';
    }

}
