import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';


@Component({
    selector: 'app-halls',
    templateUrl: './halls.component.html',
    styleUrls: ['./halls.component.css']
})
export class HallsComponent implements OnInit {
    keyword = 'name';
    showpopup = false;
    hallName: any;
    allHallList: any = [];
    modalBoxName: any = 'Create Hall';
    editHallId: any;
    editHallName: any;
    editBranchId: any;
    editBranchName: any;
    p = 1;
    loading = false;
    loadingBtn = false;
    searchField: FormControl = new FormControl();
    allBranchlist: any = [];
    errorMsg: any = [];
    branchName;
    activeBranchList: any = [];
    branchClass = -1;
    selectBranchId: any = '';
    selectBranchName: any = '';

    @ViewChild('branch', { static: true }) branch;
    constructor(private apiService: ApiService, private toastr: ToastrService, private titlecasePipe: TitleCasePipe) { }

    ngOnInit() {
        this.searchHall();
        this.listActiveBranches();
    }

    listActiveBranches() {
        this.loading = true;
        this.apiService.getData('listActiveBranches').subscribe(data => {
            this.activeBranchList = data.data;
            this.loading = false;
            if (this.activeBranchList.length !== 0) {
                setTimeout(() => {
                    const branchClick = document.querySelector('#branchname') as HTMLElement;
                    branchClick.click();
                });
            }
        }, error => {
            this.loading = false;
        });
    }

    branchNameSelect(id, name, index) {
        this.allHallList = [];
        this.searchField.patchValue('');
        this.p = 1;
        this.selectBranchId = id;
        this.selectBranchName = name;
        this.branchClass = index;
        this.listAllHalls();
    }

    openModalBox(id = '', name = '', branch = '', branchId = '') {
        this.showpopup = true;
        if (id && name) {
            this.modalBoxName = 'Edit Hall';
            this.editHallId = id;
            this.editHallName = name;
            this.editBranchName = branch;
            this.editBranchId = branchId;
            this.hallName = '';
            this.branchName = '';
        } else {
            this.modalBoxName = 'Create Hall';
            this.editHallName = '';
            this.editBranchName = '';
            this.hallName = '';
            this.editBranchId = '';
            this.branchName = '';
        }
    }

    listAllHalls() {
        this.loading = true;
        this.apiService.getData('listAllHalls', this.selectBranchId).subscribe(data => {
            this.allHallList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    createHall() {
        this.loadingBtn = true;
        const value = { hall_name: this.titlecasePipe.transform(this.hallName), branch_id: this.selectBranchId };
        this.apiService.postData(value, 'createHall').subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.hallName = '';
                this.listAllHalls();
                this.popUpClose();
                this.loadingBtn = false;
                this.branchName = '';
            } else {
                this.toastr.warning(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
                setTimeout(() => {
                    this.errorMsg = [];
                }, 3000);
            }
        }, error => {
            this.loadingBtn = false;
        });
    }

    updateHall() {
        this.loadingBtn = true;
        const value = { hall_id: this.editHallId, hall_name: this.editHallName, branch_id: this.editBranchId };
        this.apiService.postData(value, 'updateHall').subscribe((data) => {
            if (data.error === false) {
                this.toastr.success(data.message);
                this.popUpClose();
                this.modalBoxName = 'Create Hall';
                this.listAllHalls();
                this.loadingBtn = false;
            } else {
                this.toastr.warning(data.message);
                this.loadingBtn = false;
                this.errorMsg = data.data;
                setTimeout(() => {
                    this.errorMsg = [];
                }, 3000);
            }
        }, error => {
            this.loadingBtn = false;
        });
    }

    searchHall() {
        this.searchField.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged(), switchMap((query) =>
                this.apiService.searchData('searchHall', query ? `${this.selectBranchId}/${query}` : `${this.selectBranchId}/`)
            ))
            .subscribe((result) => {
                if (this.searchField.value === '') {
                    this.listAllHalls();
                    return false;
                }
                if (result.data.length === 0) {
                    this.allHallList = '';
                } else {
                    this.allHallList = result.data;
                }
            });
    }

    deleteHall(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Hall',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.apiService.getData('deleteHall', id).subscribe((data) => {
                    if (data.error === false) {
                        this.toastr.success(data.message);
                        this.listAllHalls();
                    } else {
                        this.toastr.error(data.message);
                    }
                });
                Swal.fire(
                    'Deleted!',
                    'Your Hall has been deleted.',
                    'success'
                );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Your Hall is Safe Now :)',
                    'error'
                );
            }
        });
    }

    activateHall(id) {
        this.apiService.getData('activateHall', id).subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
            } else {
                this.toastr.error(data.message);
            }
            this.listAllHalls();
        });
    }

    deActivateHall(id) {
        this.apiService.getData('deActivateHall', id).subscribe(data => {
            if (data.error === false) {
                this.toastr.success(data.message);
            } else {
                this.toastr.error(data.message);
            }
            this.listAllHalls();
        });
    }

    popUpClose() {
        this.showpopup = false;
    }

}
